<template>
    <v-toolbar>
        <v-btn class="mr-2" :to="{name: 'Reports'}">
            All Reports
        </v-btn>
        <v-btn :to="{
            name: report.link.name
        }" class="mr-2" v-for="report in staticReports.filter(x => !x.hideInBar)" :key="report.link.name"
            :color="(routeName === report.link.name ? 'info' : '')" :disabled="routeName === report.link.name">
            {{ report.name }}
        </v-btn>
    </v-toolbar>
</template>

<script>
export default {
    computed: {
        routeName() {
            return this.$route.name;
        }
    },
    created() {
        this.staticReports = require('../../data/staticReportsList').default;
    },
    data() {
        return {
            staticReports: []
        }
    },
}
</script>

<style>

</style>